html {
  overflow-x: hidden;
}
:root {
  --animate-delay: 0.3s;
}
/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.logo {
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.icon {
  top: 60%;
  left: 49%;
  transform: translate(-50%, -50%);
}

.bg {
  background-image: url("../assets/newpic.jpg");
  background-position-x: center;
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
}

#logo {
  animation-name: periodicfading;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
}

#block-gray:hover {
  background: linear-gradient(to right, transparent 50%, gray 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
}

#block-black:hover {
  background: linear-gradient(to right, transparent 50%, black 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
}

@keyframes periodicfading {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.animate-info {
  animation: fadeInUp;
  animation-duration: 1s;
}

.fast-spin {
  -webkit-animation: fa-spin 2s 500ms ease-out;
  animation: fa-spin 2s 500ms ease-out;
}

.fast-spin-left {
  -webkit-animation: spin-reverse 2s 500ms ease-out;
  animation: spin-reverse 2s 500ms ease-out;
}

@-moz-keyframes spin-reverse {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(-359deg);
  }
}
@-webkit-keyframes spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-359deg);
  }
}
@-o-keyframes spin-reverse {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(-359deg);
  }
}
@-ms-keyframes spin-reverse {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(-359deg);
  }
}
@keyframes spin-reverse {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-359deg);
  }
}
#active {
  transition: all 1s ease-in-out;
}
